<template>
    <div v-if="client" :class="['row', { inverse: is_work_provider }]" v-match-heights="{el: [`${$screen.width > 768 ? '.card-work-location' : 'none'}`]}">
        <div :class="['card card-descriptor', { [`${generate_score_class}`]: true }]">
            <p class="descriptor">{{ $t('matches.detail.person.descriptors.workLocation') }}</p> 
            <p class="percentage" v-html="$utils._computePercentageHTML(calculation_details)"></p>
        </div>
        
        <div class="col6">
            <div :class="{['card card-criteria-left nomargin-top card-work-location']: true, [`${generate_score_class}`]: is_work_provider}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.offer.workLocation.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.offer.workLocation`)}}</p>
                    
                    <p class="percentage" v-if="is_work_provider" v-html="$utils._computePercentageHTML(calculation_details)"></p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <div v-if="workProviderOffer.hybrid.hybrid" class="inline">
                            <span class="pill">{{ $t('matches.workLocations.hybrid') }}</span>
                            <p v-html="$t('workProviderOffer.shared.card.hybrid.days.criteria', { days: workProviderOffer.hybrid.days.number, period: $t(`workProviderOffer.shared.card.hybrid.days.periods.${workProviderOffer.hybrid.days.period}.regular`) })"></p>
                        </div>

                        <span v-else class="pill">{{ $t(`matches.workLocations.${workProviderOffer.workLocation}`) }}</span>
                    </div>
                </div>  
            </div>
        </div>

        <div class="col6">
            <div :class="{['card card-criteria-right nomargin-top card-work-location']: true, [`${generate_score_class}`]: is_person}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.${type}.workLocation.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.${type}.workLocation`)}}</p>
                    
                    <p class="percentage" v-if="is_person" v-html="$utils._computePercentageHTML(calculation_details)"></p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <div v-if="is_person">
                            <div v-if="offer.hybrid.hybrid" class="inline">
                                <span class="pill">{{ $t('matches.workLocations.hybrid') }}</span>
                                <p v-html="$t('workProviderOffer.shared.card.hybrid.days.criteria', { days: offer.hybrid.days.number, period: $t(`workProviderOffer.shared.card.hybrid.days.periods.${offer.hybrid.days.period}.regular`) })"></p>
                            </div>

                            <span v-else class="pill">{{ $t(`matches.workLocations.${offer.workLocation}`) }}</span>
                        </div>

                        <div v-else class="pill-container">
                            <span class="pill">{{ $t(offer.workLocation) }}</span>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchDetailCriteriaWorkLocationCard',

    props: {
        client: { type: String, default: '' },
        type: { type: String, default: '' },

        match: {
            type: Object,
            default() {
                return {}
            }
        },

        workProviderOffer: {
            type: Object,
            default() {
                return {}
            }
        },

        offer: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    computed: {
        is_work_provider: function() {
            return this.client === 'workProvider';
        },

        is_person: function() {
            return this.client === 'person';
        },

        generate_score_class: function() {
            const self = this;
            return self.workProviderOffer.hybrid.hybrid || self.workProviderOffer.hybrid.hybrid ? self.$utils._generate_score_class(self.match, 'hybrid') : self.$utils._generate_score_class(self.match, 'workLocation');
        },

        calculation_details: function() {
            const self = this;
            return self.workProviderOffer.hybrid.hybrid || self.workProviderOffer.hybrid.hybrid ? self.match.calculationDetails['hybrid'] : self.match.calculationDetails['workLocation'];
        },

        device: function() {
            return this.$screen.width > 768 ? 'desktop' : 'mobile';
        }
    }
}
</script>

