<template>
    <div v-if="loaded" class="question benefits column combined">
        <div class="row">
            <div class="col3">
                <div class="choice-group">
                    <p class="choice-title">{{$t('benefits.titles.vacation')}}</p>
                    <div class="checkbox-container column combined">
                        <div v-for="choice in vacation_choices" :key="choice" :class="['checkbox-with-label', { readonly }]">
                            <font-awesome-icon :class="[{ 'checked_offer': data.benefits.offer.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.offer.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />
                            <font-awesome-icon :class="[{ 'checked_person': data.benefits.person.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.person.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />
                            <p class="label">{{$t(`benefits.${choice}`)}}</p>
                        </div>
                    </div>
                </div>

                <div class="choice-group with-spacing spacing-top">
                    <p class="choice-title">{{$t('benefits.titles.budget')}}</p>
                    <div class="checkbox-container column combined">
                        <div v-for="choice in budget_choices" :key="choice" :class="['checkbox-with-label', { readonly }]">
                            <font-awesome-icon :class="[{ 'checked_offer': data.benefits.offer.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.offer.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />
                            <font-awesome-icon :class="[{ 'checked_person': data.benefits.person.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.person.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />
                            <p class="label">{{$t(`benefits.${choice}`)}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <span class="row-separator"></span>

            <div class="col4">
                <div class="choice-group with-spacing spacing-left">
                    <p class="choice-title">{{$t('benefits.titles.insurance')}}</p>
                    <div class="checkbox-container column combined">
                        <div v-for="choice in insurance_choices" :key="choice" :class="['checkbox-with-label', { readonly }]">
                            <font-awesome-icon :class="[{ 'checked_offer': data.benefits.offer.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.offer.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />
                            <font-awesome-icon :class="[{ 'checked_person': data.benefits.person.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.person.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />                    
                            <p class="label">{{$t(`benefits.${choice}`)}}</p>
                        </div>
                    </div>
                </div>

                <div class="choice-group with-spacing spacing-left spacing-top">
                    <p class="choice-title">{{$t('benefits.titles.rrsp')}}</p>
                    <div class="checkbox-container column combined">
                        <div v-for="choice in rrsp_choices" :key="choice" :class="['checkbox-with-label', { readonly }]">
                            <font-awesome-icon :class="[{ 'checked_offer': data.benefits.offer.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.offer.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />
                            <font-awesome-icon :class="[{ 'checked_person': data.benefits.person.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.person.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />                            
                            <p class="label">{{$t(`benefits.${choice}`)}}</p>
                        </div>
                    </div>
                </div>

                <div class="choice-group with-spacing spacing-left spacing-top">
                    <p class="choice-title">{{$t('benefits.titles.pension')}}</p>
                    <div class="checkbox-container column combined">
                        <div v-for="choice in pension_choices" :key="choice" :class="['checkbox-with-label', { readonly }]">
                            <font-awesome-icon :class="[{ 'checked_offer': data.benefits.offer.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.offer.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />
                            <font-awesome-icon :class="[{ 'checked_person': data.benefits.person.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.person.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />                          
                            <p class="label">{{$t(`benefits.${choice}`)}}</p>
                        </div>
                    </div>
                </div>
            </div>

            <span class="row-separator"></span>

            <div class="col3">
                <div class="choice-group with-spacing spacing-left">
                    <p class="choice-title">{{$t('benefits.titles.other')}}</p>
                    <div class="checkbox-container column combined">
                        <div v-for="choice in other_choices" :key="choice" :class="['checkbox-with-label', { readonly }]">
                            <font-awesome-icon :class="[{ 'checked_offer': data.benefits.offer.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.offer.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />
                            <font-awesome-icon :class="[{ 'checked_person': data.benefits.person.choices.includes(choice), 'unchecked': !data.benefits.offer.choices.includes(choice) }]" :icon="data.benefits.person.choices.includes(choice) ? ['fa', 'square-check'] : ['far', 'square']" />                
                            <p class="label">{{$t(`benefits.${choice}`)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="other">
            <p class="title">{{$t('benefits.titles.other_offer')}}</p>
            <input type="text" :class="['input input-text', { readonly }]" v-model="data.benefits.offer.other">
            
            <p class="title">{{$t('benefits.titles.other_worker')}}</p>
            <input type="text" :class="['input input-text', { readonly }]" v-model="data.benefits.person.other">
        </div>
    </div>
</template>

<script>
export default {
    name: 'BenefitsCombinedComponent',

    props: {
        benefits: {
            type: Object,
            default() {
                return {}
            }
        },

        readonly: {
            type: Boolean,
            default: false
        },
    },

    watch: {
        benefits: function(val) {
            this.data.benefits = val;
        },

        data: {
            deep: true,
            handler() {
                const self = this;
                if(self.loaded) {
                    self.$emit('update', self.data)
                }   
            }
        },
    },

    computed: {
        vacation_choices: function() { return this.choices.filter(q => q.startsWith('vacation_')) },
        insurance_choices: function() { return this.choices.filter(q => q.startsWith('insurance_')) },
        rrsp_choices: function() { return this.choices.filter(q => q.startsWith('rrsp_')) },
        budget_choices: function() { return this.choices.filter(q => q.startsWith('budget_')) },
        pension_choices: function() { return this.choices.filter(q => q.startsWith('pension_')) },
        other_choices: function() { return this.choices.filter(q => q.startsWith('other_')) }
    },

    data() {
        return {
            data: {
                benefits: {
                    choices: [],
                    other: ''
                },
            },

            choices: [
                'vacation_1_week',
                'vacation_2_week',
                'vacation_3_week',
                'vacation_4_week',
                'vacation_unlimited',

                'insurance_medicine',
                'insurance_dental',
                
                'insurance_vision',
                
                'rrsp_collective_with_contribution',
                'rrsp_collective',

                'pension_defined_benefits',
                'pension_undefined_benefits',
                
                'budget_health',
                'budget_learning',
                
                'other_food',
                'other_stock_options',
                'other_performance_bonus',
                'other_cellphone_paid',
                'other_internet_paid',
                'other_expense_account',
            ],

            loaded: false
        }
    },

    mounted() {
        const self = this;

        if(self.benefits) {
            self.data = {
                benefits: self.benefits,
            };

            self.$nextTick(() => {
                self.loaded = true;
            })
        }
    }
}
</script>

