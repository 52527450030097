<template>
    <div class="modal" :class="{active: modal}">
        <div class="modal-card unlock">
            <div class="title-container">
                <p class="title" v-html="$t('matches.detail.workProvider.modal.unlockCandidate.imInterestedConfTitle')"></p>
            </div>
            
            <div class="modal-body">
                <p v-html="$t('matches.detail.workProvider.modal.unlockCandidate.imInterestedConfDesc')"></p> 
                <!-- <p class="title">{{$t('matches.detail.workProvider.modal.unlockCandidate.locations')}}</p> -->

                <!-- <div v-if="!notEnoughCredits" :class="['location-container', error('selectedMatches')]">
                    <div :class="['location', { active: data.selectedMatches.includes(match._id) }]"  v-for="match in filteredMatches" :key="match._id" @click="selectMatch(match._id)">
                        <div class="text">
                            <span>
                                <font-awesome-icon :class="{ active: data.selectedMatches.includes(match._id) }" :icon="data.selectedMatches.includes(match._id) ? ['fa', 'circle-check'] : ['far', 'circle']" />
                                <p class="address">{{getFormattedAddressForMatch(match)}}</p>
                            </span>
                            
                            <p class="percentage" v-html="$utils._computePercentageHTML(match.matchPercentage)"></p>
                        </div>
                    </div>
                </div> -->

                <div class="unlock-cost">
                    <div class="title-container">
                        <p class="title nomargin">{{$t('matches.detail.workProvider.modal.unlockCandidate.unlockCosts.title')}}</p>
                        <span class="bubble">Inclus</span>
                    </div>

                    <div v-if="notEnoughCredits" class="title-container error">
                        <p class="title nomargin" v-html="$t('matches.detail.workProvider.modal.unlockCandidate.notEnoughCredits.title', {credits: missing_credits})"></p>
                    </div>
                </div>
                
                <PurchaseCredits v-if="notEnoughCredits" :embedded="true" :missingCredits="missing_credits" :creditUnitCost="credit_unit_cost" @cancel="close()" @purchased_credits="notEnoughCredits = false" />
            </div>

            <div v-if="!notEnoughCredits" class="cta-container">
                <div class="cta cta-secondary cta-slim" :class="{'loading dark': loading}" @click="unlock()">{{$t('matches.detail.workProvider.modal.unlockCandidate.imStillInterested')}}</div>
                <div class="cta cta-outline dark cta-slim" @click="close()">{{$t('language.words.neverMind')}}</div>
            </div>
        </div>

    </div>
</template>

<script>
import PurchaseCredits from '../../../../components/Cards/Credits/PurchaseCreditsCard.vue'

export default {
    name: 'WorkProviderMatchDetailUnlockCandidate',

    components: { PurchaseCredits },

    props: {
        id: {
            type: String,
            default() {
                return ''
            }
        },
        
        workProviderOfferId: {
            type: String,
            default() {
                return ''
            }
        },

        show: {
            type: Boolean,
            default: false
        },

        name: {
            type: String,
            default() {
                return ''
            }
        },

        matchPercentage: {
            type: Number,
            default() {
                return 0
            }
        },

        contactMethod: {
            type: Object,
            default: null
        },

        matches: {
            type: Array,
            default() {
                return []
            }
        },

        workProviderOfferLocations: {
            type: Array,
            default() {
                return []
            }
        },

        type: {
            type: String,
            default: ''
        },
    },

    watch: {
        show: function(val) {
            this.modal = val;
        },

        modal: function() {
            const self = this;
            if(self.modal) { 
                self.$utils._open_modal(); 
                self.set_matches();
            }
            else { self.$utils._close_modal(); }
        }
    },

    data() {
        return {
            data: {
                selectedMatches: []
            },

            validation: {
                selectedMatches: {
                    error: false,
                    key: 'errors.validation.fieldMandatory'
                }
            },

            modal: false,
            loading: false,

            notEnoughCredits: false,
            missing_credits: 0,
            credit_unit_cost: 0
        }
    },
    
    computed: {
        generateContactMethodLabel: function() {
            return `<span>${this.$t('matches.detail.workProvider.modal.unlockCandidate.sharedInformation.contactMeBy')} <b><em>${this.$t(`matches.detail.workProvider.modal.unlockCandidate.sharedInformation.methods.${this.contactMethod.method}`)}</em></b> au <b><em>${this.contactMethod.info}</em></b></span>`;
        },

        filteredMatches: function() {
            const self = this;
            return self.matches.filter(match => match.stage === 'interested').sort(match => match.workLocation === 'regular');
        }
    },

    methods: {
        validate_mandatory_fields: function() {
            const self = this;

            self.formValidated = true;
            self.validation.selectedMatches.error = false;

            if(self.data.selectedMatches.length === 0) {
                self.validation.selectedMatches.error = true;
                self.formValidated = false;
            }
        },

        unlock: function() {
            const self = this;  

            self.validate_mandatory_fields();
            if(self.formValidated) {
                self.loading = true;
                const data = {
                    stage: 'unlocked',
                    selectedMatches: self.data.selectedMatches
                }
                
                self.$axios
                    .post(`matches/${self.type}/stage`, data)
                    .then(response => {
                        if(response.status == 200) {
                            self.$utils._navigate_to_name_with_params('work-provider-offer', { id: self.workProviderOfferId, state: 'consult' });
                        }
                    })
                    .catch(error => {
                        if(error && error.response && error.response.data && error.response.data.error && error.response.data.error.key === 'notEnoughCredits') {
                            self.notEnoughCredits = true;
                            self.missing_credits = error.response.data.missing_credits;
                            self.credit_unit_cost = error.response.data.credit_unit_cost;
                        }else {
                            if(error && error.response) {
                                if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                            } else { console.log(error); }
                        }
                        
                        self.loading = false;
                    });
            } else {
                self.$toasted.error(
                    self.$t('notifications.allFieldsAreMandatory'),
                    { icon: 'circle-exclamation' }
                );
            }
        },

        selectMatch: function(id) {
            const self = this;

            if(self.data.selectedMatches.includes(id)) {
                if(self.data.selectedMatches.indexOf(id) !== -1)
                    self.data.selectedMatches.splice(self.data.selectedMatches.indexOf(id), 1);
            }else {
                self.data.selectedMatches.push(id)
            }
        },

        getFormattedAddressForMatch: function(match) {
            const self = this;
            
            if(match && match.workLocation === 'regular' && match.workProviderOfferLocationId) {
                return self.workProviderOfferLocations.find(location => location._id.toString() === match.workProviderOfferLocationId.toString()).formattedAddress
            }else if (match && match.workLocation === 'remote') {
                return self.$t('matches.workLocations.remote')
            }
        },

        close: function() {
            const self = this;

            self.validation.selectedMatches.error = false;
            self.notEnoughCredits = false;
            self.modal = false;
            self.$emit('close');
        },

        error: function(key) {
            try {
                return { error: this.validation[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        },

        set_matches: function() {
            const self = this;
            self.data.selectedMatches = self.filteredMatches.map(match => match._id);
        }
    },
}
</script>

