<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope" :pace="{ activate: true, loaded: loaded }">
        <div id="sticky-heading-container" class="heading-container sticky">
            <div class="back-title-container">
                <BackButton />
                <h1 class="title" v-if="loaded" v-html="`${$t(`pages.matches.${type}.detail.title`)} | ${$utils._computePercentageHTML(match.matchPercentage)}`"></h1>
            </div>
            
            <div class="cta-container" v-if="loaded && globalStage === 'interested'">
                <div class="cta cta-primary cta-slim" @click="modalUnlockCandidate = true">{{$t('matches.detail.workProvider.unlockCandidate')}}</div>
                <div class="cta cta-outline dark cta-slim" @click="modalImNotInterested = true">{{$t('matches.detail.workProvider.imNotInterested')}}</div>
            </div>
        </div>
        
        <div id="with-sticky-header" class="cards with-sticky-header">
            <div v-if="loaded">
                <MatchDetailCriteriaContactCard v-if="globalStage === 'unlocked'" :contactMethod="contactMethod" :client="'workProvider'" />
                <div v-if="globalStage === 'unlocked'" class="card card-congrats animate__animated animate__fadeIn">
                    <p v-html="$t('matches.detail.workProvider.card.congrats.title')"></p>
                </div>
                
                <div :class="[{'reduced_opacity': globalStage === 'unlocked'}]">
                    <MatchDetailSummaryCard :workProviderOffer="workProviderOffer" :match="match" :matches="matches" :workProviderOfferLocations="workProviderOfferLocations" @updateMatch="match = $event"  :name="name" :globalStage="globalStage" :client="'workProvider'" :type="type" />
                    <MatchDetailCriteriaHeaderCard :match="match" :client="'workProvider'" :type="type" />
                    
                    <MatchDetailCriteriaClassificationCard v-if="IS_TYPE_WORKER" :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" />
                    
                    <MatchDetailCriteriaWorkDaysCard :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" :type="type"/>
                    <MatchDetailCriteriaWorkTimesCard :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" :type="type"/>
                    <MatchDetailCriteriaHoursPerWeekCard :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" :type="type"/>
                    <MatchDetailCriteriaSalariesCard v-if="IS_TYPE_WORKER" :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" />
                    <MatchDetailCriteriaExperienceLevelCard :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" />
                    <MatchDetailCriteriaWorkLocationCard :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" :type="type"/>
                    <MatchDetailCriteriaDistanceCard v-if="match.workLocation === 'regular'" :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" :type="type"/>
                    <MatchDetailCriteriaStartDateCard :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" :type="type"/>
                    <MatchDetailBenefitsCard :match="match" :workProviderOffer="workProviderOffer" :offer="offer" :client="'workProvider'" :type="type" />
                </div>
            </div>
        </div>

        <WorkProviderMatchDetailUnlockCandidateModal    :id="match._id" 
                                                        :matches="matches"
                                                        :workProviderOfferLocations="workProviderOfferLocations"
                                                        :show="modalUnlockCandidate" 
                                                        :name="name" 
                                                        :matchPercentage="match.matchPercentage"
                                                        :contactMethod="contactMethod"
                                                        :type="type"
                                                        :workProviderOfferId="workProviderOffer._id"
                                                        @close="modalUnlockCandidate = false" />
        
        <WorkProviderMatchDetailNotUnlockCandidateModal :id="match._id"
                                                            :show="modalImNotInterested" 
                                                            :type="type"
                                                            :workProviderOfferId="workProviderOffer._id"
                                                            :parentMatchId="parentMatchId"
                                                            @close="modalImNotInterested = false"   />
        
        <div class="modal" :class="{ active: deleted }">
            <div class="modal-card match-deleted">
                <p class="title" v-html="$t('matches.detail.workProvider.deleted.title')"></p>
                <p class="description" v-html="$t('matches.detail.workProvider.deleted.description')"></p>
                
                <div class="cta-container">
                    <div class="cta cta-outline dark cta-slim" @click="closeAndBack()">{{$t('language.words.back')}}</div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { bus } from '../../../../main';

import Layout from '../../../../components/Layout.vue';
import BackButton from '../../../../components/Navigation/BackButton.vue';
import MatchDetailSummaryCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailSummaryCard.vue';
import MatchDetailCriteriaHeaderCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaHeaderCard.vue';
import MatchDetailCriteriaContactCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaContactCard.vue';
import MatchDetailCriteriaClassificationCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaClassificationCard.vue';
import MatchDetailCriteriaWorkDaysCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaWorkDaysCard.vue';
import MatchDetailCriteriaWorkTimesCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaWorkTimesCard.vue';
import MatchDetailCriteriaHoursPerWeekCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaHoursPerWeekCard.vue';
import MatchDetailCriteriaWorkLocationCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaWorkLocationCard.vue';
import MatchDetailCriteriaDistanceCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaDistanceCard.vue';
import MatchDetailCriteriaStartDateCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaStartDateCard.vue';
import MatchDetailCriteriaSalariesCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaSalariesCard.vue';
import MatchDetailCriteriaExperienceLevelCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailCriteriaExperienceLevelCard.vue';
import MatchDetailBenefitsCard from '../../../../components/Cards/Matches/MatchDetail/MatchDetailBenefitsCard.vue';

import WorkProviderMatchDetailUnlockCandidateModal from '../../../../components/Modals/MatchDetail/WorkProvider/WorkProviderMatchDetailUnlockCandidate.vue';
import WorkProviderMatchDetailNotUnlockCandidateModal from '../../../../components/Modals/MatchDetail/WorkProvider/WorkProviderMatchDetailNotUnlockCandidate.vue';

export default {
    name: 'WorkProviderMatchDetail',

    components: {
        Layout,
        BackButton,

        MatchDetailSummaryCard,
        MatchDetailCriteriaHeaderCard,
        MatchDetailCriteriaContactCard,
        MatchDetailCriteriaClassificationCard,
        MatchDetailCriteriaWorkDaysCard,
        MatchDetailCriteriaWorkTimesCard,
        MatchDetailCriteriaHoursPerWeekCard,
        MatchDetailCriteriaWorkLocationCard,
        MatchDetailCriteriaDistanceCard,
        MatchDetailCriteriaStartDateCard,
        MatchDetailCriteriaSalariesCard,
        MatchDetailCriteriaExperienceLevelCard,
        MatchDetailBenefitsCard,

        WorkProviderMatchDetailUnlockCandidateModal,
        WorkProviderMatchDetailNotUnlockCandidateModal
    },

    props: {
        id: {
            type: String,
            default: null
        },

        navigation: {
            type: String,
            default: 'simple'
        },
 
        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'work-provider-match-detail',
            classes: { dashboard: true, 'match-detail': true },
            
            match: {},
            matches: [],
            offer: {},
            workProviderOffer: {},
            workProviderOfferLocations: [],
            workProviderOfferLocation: {},
            contactMethod: {},
            name: '',
            globalStage: '',
            parentMatchId: '',

            loaded: false,

            modalUnlockCandidate: false,
            modalImNotInterested: false,

            IS_TYPE_VOLUNTEER: false,
            IS_TYPE_WORKER: false,

            type: '',

            deleted: false
        }
    },

    watch: {
        deleted: function() {
            const self = this;
            if(self.deleted) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        },
    },

    computed: {
        ...mapGetters([
            'currentProfileType',
        ])
    },

    methods: {
        getMatch: function() {
            const self = this;
            self.type = self.currentProfileType === 'paid' ? 'worker' : 'volunteer'

            if(self.id) {
                self.$axios
                    .get(`${self.type}/match/${self.id}/workProvider`)
                    .then(response => {
                        if(response.status == 200) {
                            const data = response.data;
                            self.matches = data.matches;
                            self.workProviderOffer = data.workProviderOffer;
                            self.name = data.name;
                            self.offer = data.offer;
                            self.workProviderOfferLocations = data.workProviderOfferLocations;
                            self.globalStage = data.globalStage;
                            self.contactMethod = data.contactMethod
                            
                            self.matches.forEach((match, index) => {
                                self.matches[index].calculationDetails = JSON.parse(match.calculationDetails);
                                self.matches[index].stages = JSON.parse(match.stages);
                            });

                            self.match = self.matches[self.matches.length - 1];
                            self.parentMatchId = self.match.parentMatchId;
                            self.workProviderOfferLocation = self.workProviderOfferLocations.find(loc => loc._id === self.match.workProviderOfferLocationId)
                            self.loaded = true;

                            self.IS_TYPE_VOLUNTEER = self.type === 'volunteer';
                            self.IS_TYPE_WORKER = self.type === 'worker';

                            if(self.match && self.globalStage === 'new'){
                                self.isLoadingImInterested = false;
                                self.isLoadingImNotInterested = false;
                            }

                            self.$nextTick(() => {
                                bus.$emit('reset_heading_container_margin', true);
                            })
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }

                        if(error && error.response && error.response.status && error.response.status === 400) {
                            if(error.response.data && error.response.data.key) {
                                if(error.response.data.key === 'parentMatchDoesNotExist') {
                                    self.deleted = true;
                                }
                            }
                        }
                    });
            }
        },

        imInterested: function() {
            const self = this; 

            self.isLoadingImInterested = true;
            self.updateVolunteerMatchStage('interested', self.redirect);
        },

        imNotInterested: function() {
            const self = this; 
            
            self.isLoadingImNotInterested = true;
            self.updateVolunteerMatchStage('notInterested', self.redirect);
        },

        redirect: function(stage) {
            const self = this;

            if(stage === 'interested' || stage === 'notInterested') {
                self.$utils._navigate_to_name('volunteer-matches')
            }
        },

        closeAndBack: function() {
            const self = this;

            self.deleted = false;
            self.$utils._navigate_to_name('dashboard');
        }
    },

    mounted() {
        const self = this; self.getMatch();
    }
}
</script>

