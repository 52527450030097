<template>
    <div v-if="client && $screen.width > 1175" :class="['row', { inverse: is_work_provider }]">
        <div class="col6">
            <div class="card card-criteria-left nomargin-top card-benefits">
                <div class="card-heading">
                    <p class="title">{{$t(`matches.detail.${client}.card.offer.benefits`)}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <BenefitsComponent :benefits="workProviderOffer.benefits"  :readonly="true"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col6">
            <div class="card card-criteria-right nomargin-top card-benefits">
                <div class="card-heading">
                    <p class="title">{{$t(`matches.detail.${client}.card.${type}.benefits`)}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <BenefitsComponent :benefits="offer.benefits"  :readonly="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else :class="['row']">
        <div :class="['card card-descriptor']">
            <p class="descriptor">{{ $t('matches.detail.person.descriptors.benefits') }}</p> 
        </div>

        <div class="col12">
            <div class="card nomargin-top card-benefits combined">
                <div class="card-heading">
                    <div v-if="is_person">
                        <div class="checkbox-container">
                            <p class="title">{{$t(`matches.detail.${client}.card.offer.benefits`)}}</p>
                            <font-awesome-icon class="checked_offer" :icon="['fa', 'square-check']" />
                        </div>

                        <div class="checkbox-container">
                            <p class="title">{{$t(`matches.detail.${client}.card.worker.benefits`)}}</p>
                            <font-awesome-icon class="checked_person" :icon="['fa', 'square-check']" />
                        </div>
                    </div>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.offer.benefits`)}}</p>
                </div>

                <div class="card-body">
                    <div class="question-wrapper">
                        <BenefitsCombinedComponent :benefits="{ offer: workProviderOffer.benefits, person: offer.benefits }"  :readonly="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BenefitsComponent from '../../../Form/BenefitsComponent.vue'
import BenefitsCombinedComponent from '../../../Form/BenefitsCombinedComponent.vue'

export default {
    name: 'MatchDetailBenefitsCard',

    components: { BenefitsComponent, BenefitsCombinedComponent },

    props: {
        client: { type: String, default: '' },
        type: { type: String, default: '' },

        match: {
            type: Object,
            default() {
                return {}
            }
        },

        workProviderOffer: {
            type: Object,
            default() {
                return {}
            }
        },

        offer: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    computed: {
        is_work_provider: function() {
            return this.client === 'workProvider';
        },

        is_person: function() {
            return this.client === 'person';
        },

        device: function() {
            return this.$screen.width > 768 ? 'desktop' : 'mobile';
        }
    }
}
</script>

