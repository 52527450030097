<template>
    <div v-if="client" :class="['row', { inverse: is_work_provider }]" v-match-heights="{el: [`${$screen.width > 768 ? '.card-salaries' : 'none'}`]}">
        <div :class="['card card-descriptor', { [`${generate_score_class}`]: true }]">
            <p class="descriptor">{{ $t('matches.detail.person.descriptors.salary') }}</p> 
            <p class="percentage" v-html="$utils._computePercentageHTML(match.calculationDetails['salary'])"></p>
        </div>
        
        <div class="col6">
            <div :class="{['card card-criteria-left nomargin-top card-salaries']: true, [`${generate_score_class}`]: is_work_provider}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.offer.salary.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.offer.salary`)}}</p>
                    
                    <p class="percentage" v-if="is_work_provider" v-html="$utils._computePercentageHTML(match.calculationDetails['salary'])"></p>
                </div>
                
                <div class="card-body">
                    <div v-if="client === 'person'" class="pill-container">
                        <span v-if="workProviderOffer.salaries && workProviderOffer.salaries.length !== 0" class="pill" v-html="`$${$utils._formatSalary(workProviderOffer.salaries[0])}${compare('workProvider')}`"></span>
                        <span class="text" v-else>{{$t(`matches.detail.${client}.card.salaries.missing`)}}</span>
                    </div>

                    <div v-else class="pill-container">
                        <div v-for="salary in workProviderOffer.salaries" :key="salary.experienceLevel" class="pill-with-text">
                            <span class="text">{{$t(`workProviderOffer.paid.card.experienceLevels.choices.${salary.experienceLevel}`)}}</span>
                            <span class="pill" v-html="`$${$utils._formatSalary(salary)}`"></span>
                        </div>
                    </div>
                    
                    <div class="disclaimer" v-if="client === 'workProvider' && !workProviderOffer.classification.experienceLevels.includes(offerExperienceLevel)">
                        <span class="text">{{$t(`matches.detail.${client}.card.experienceLevels.missing`)}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col6">
            <div :class="{['card card-criteria-right nomargin-top card-salaries']: true, [`${generate_score_class}`]: is_person}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.worker.salary.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.worker.salary`)}}</p>
                    
                    <p class="percentage" v-if="is_person" v-html="$utils._computePercentageHTML(match.calculationDetails['salary'])"></p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <span class="pill" v-html="`$${$utils._formatSalary(offer.salary)}${compare('offer')}`"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchDetailCriteriaSalariesCard',

    props: {
        match: {
            type: Object,
            default() {
                return {}
            }
        },

        workProviderOffer: {
            type: Object,
            default() {
                return {}
            }
        },

        offer: {
            type: Object,
            default() {
                return {}
            }
        },

        client: {
            type: String,
            default() {
                return ''
            }
        }
    },

    computed: {
        is_work_provider: function() {
            return this.client === 'workProvider';
        },

        is_person: function() {
            return this.client === 'person';
        },

        generate_score_class: function() {
            return this.$utils._generate_score_class(this.match, 'salary');
        },

        offerExperienceLevel: function() {
            return this.offer.classification.experienceLevel === 'empty' ? 'none' : this.offer.classification.experienceLevel
        },

        device: function() {
            return this.$screen.width > 768 ? 'desktop' : 'mobile';
        }
    },

    methods: {
        compare: function(src) {
            const self = this;

            if(self.workProviderOffer.salaries.length !== 0) {
                const workProviderOfferSalary = self.workProviderOffer.salaries[0];
                const offerSalary = self.offer.salary;

                if(workProviderOfferSalary.type !== offerSalary.type) {
                    if(src === 'workProvider' && workProviderOfferSalary.type === 'yearly') {
                        return `${self.$t('language.per_year')}, <b>${self.$t('equalsTo')}</b> $${(parseFloat(workProviderOfferSalary.amount) / 52 /self.workProviderOffer.hoursPerWeek).toFixed(2)}`
                    }

                    if(src === 'offer' && offerSalary.type === 'yearly') {
                        return `${self.$t('language.per_year')}, <b>${self.$t('equalsTo')}</b> $${(parseFloat(offerSalary.amount) / 52 /self.offer.hoursPerWeek).toFixed(2)}`
                    }
                }
            }
            

            return ''
        },
    }
}
</script>

