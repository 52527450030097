<template>
    <div v-if="client" :class="['row', { inverse: is_work_provider }]" v-match-heights="{el: [`${$screen.width > 768 ? '.card-hours-per-week' : 'none'}`]}">
        <div :class="['card card-descriptor', { [`${generate_score_class}`]: true }]">
            <p class="descriptor">{{ $t('matches.detail.person.descriptors.hoursPerWeek') }}</p> 
            <p class="percentage" v-html="$utils._computePercentageHTML(match.calculationDetails['hoursPerWeek'])"></p>
        </div>
        
        <div class="col6">
            <div :class="{['card card-criteria-left nomargin-top card-hours-per-week']: true, [`${generate_score_class}`]: is_work_provider}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.offer.hoursPerWeek.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.offer.hoursPerWeek`)}}</p>
                    
                    <p class="percentage" v-if="is_work_provider" v-html="$utils._computePercentageHTML(match.calculationDetails['hoursPerWeek'])"></p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <div v-if="workProviderOffer.hours_per_week_persons_choice === true">
                            <span class="pill">{{ $t(`matches.detail.${client}.card.hours_per_week_persons_choice`) }}</span>
                        </div>
                        <div v-else>
                            <span class="pill">{{ `${workProviderOffer.hoursPerWeek} ${$t('language.words.hours')}` }}</span>
                            <span class="pill-label">{{ $t('language.hours_per_week') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col6">
            <div :class="{['card card-criteria-right nomargin-top card-hours-per-week']: true, [`${generate_score_class}`]: is_person}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.${type}.hoursPerWeek.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.${type}.hoursPerWeek`)}}</p>
                    
                    <p class="percentage" v-if="is_person" v-html="$utils._computePercentageHTML(match.calculationDetails['hoursPerWeek'])"></p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <span class="pill">{{ `${offer.hoursPerWeek} ${$t('language.words.hours')}` }}</span>
                        <span class="pill-label">{{ $t('language.hours_per_week') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchDetailCriteriaHoursPerWeekCard',

    props: {
        client: { type: String, default: '' },
        type: { type: String, default: '' },

        match: {
            type: Object,
            default() {
                return {}
            }
        },

        workProviderOffer: {
            type: Object,
            default() {
                return {}
            }
        },

        offer: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    computed: {
        is_work_provider: function() {
            return this.client === 'workProvider';
        },

        is_person: function() {
            return this.client === 'person';
        },

        generate_score_class: function() {
            return this.$utils._generate_score_class(this.match, 'hoursPerWeek')
        },

        device: function() {
            return this.$screen.width > 768 ? 'desktop' : 'mobile';
        }
    }
}
</script>

