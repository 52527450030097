<template>
    <div v-if="client" :class="['row', { inverse: is_work_provider }]" v-match-heights="{el: [`${$screen.width > 768 ? '.card-work-times.card-body' : 'none'}`]}">
        <div :class="['card card-descriptor', { [`${generate_score_class}`]: true }]">
            <p class="descriptor">{{ $t('matches.detail.person.descriptors.workTimes') }}</p> 
            <p class="percentage" v-html="$utils._computePercentageHTML(match.calculationDetails['workTimes'])"></p>
        </div>

        <div class="col6">
            <div :class="{['card card-criteria-left nomargin-top card-work-times']: true, [`${generate_score_class}`]: is_work_provider}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.offer.workTimes.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.offer.workTimes`)}}</p>
                    
                    <p class="percentage" v-if="is_work_provider" v-html="$utils._computePercentageHTML(match.calculationDetails['workTimes'])"></p>
                </div>
                
                <div class="card-body">
                    <WorkTimesComponent  v-if="workProviderOffer.workTimes"
                                        :workDays="workProviderOffer.workDays"
                                        :workTimes="workProviderOffer.workTimes"
                                        :header="false"
                                        :readonly="true"
                                        :in_match_detail="true" />
                </div>
            </div>
        </div>

        <div class="col6">
            <div :class="{['card card-criteria-right nomargin-top card-workTimes']: true, [`${generate_score_class}`]: is_person}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.${type}.workTimes.${device}`)}}</p>                    
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.${type}.workTimes`)}}</p>                    
                    
                    <p class="percentage" v-if="is_person" v-html="$utils._computePercentageHTML(match.calculationDetails['workTimes'])"></p>
                </div>

                <div class="card-body">
                    <WorkTimesComponent  v-if="offer.workTimes"
                                        :workDays="offer.workDays"
                                        :workTimes="offer.workTimes"
                                        :header="false"
                                        :readonly="true"
                                        :in_match_detail="true" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WorkTimesComponent from '../../../Form/WorkTimesComponent.vue'

export default {
    name: 'MatchDetailCriteriaWorkTimesCard',

    components: {
        WorkTimesComponent
    },

    props: {
        client: { type: String, default: '' },
        type: { type: String, default: '' },

        match: {
            type: Object,
            default() {
                return {}
            }
        },

        workProviderOffer: {
            type: Object,
            default() {
                return {}
            }
        },

        offer: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    watch: {
        workProviderOffer: {
            deep: true,
            handler() {
                document.dispatchEvent(new CustomEvent('matchheight', {}))
            }
        },
        
        offer: {
            deep: true,
            handler() {
                document.dispatchEvent(new CustomEvent('matchheight', {}))
            }
        },


    },

    computed: {
        is_work_provider: function() {
            return this.client === 'workProvider';
        },

        is_person: function() {
            return this.client === 'person';
        },

        generate_score_class: function() {
            return this.$utils._generate_score_class(this.match, 'workTimes')
        },

        device: function() {
            return this.$screen.width > 768 ? 'desktop' : 'mobile';
        }
    },

    mounted() {
        const self = this;

        self.$utils._initializeWorkTimes(self.workProviderOffer.workTimes);
        self.$utils._re_order_work_times(self.workProviderOffer.workTimes);

        self.$utils._initializeWorkTimes(self.offer.workTimes);
        self.$utils._re_order_work_times(self.offer.workTimes);
    }
}
</script>

