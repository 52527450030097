<template>
    <div class="modal" :class="{active: modal}">
        <div class="modal-card not-unlocked">
            <div class="modal-body">
                <div class="checkbox-container">
                    <p class="title">{{$t('matches.detail.workProvider.modal.notUnlocked.reasons.title')}}</p>

                    <div class="checks">
                            <div class="input-container">
                            <p class="label">{{$t(`matches.detail.workProvider.modal.notUnlocked.reasons.classification`)}}</p>
                            <input type="checkbox" v-model="reasons.classification">
                        </div>

                        <div class="input-container">
                            <p class="label">{{$t('matches.detail.workProvider.modal.notUnlocked.reasons.workDays')}}</p>
                            <input type="checkbox" v-model="reasons.workDays">
                        </div>

                        <div class="input-container">
                            <p class="label">{{$t('matches.detail.workProvider.modal.notUnlocked.reasons.workTimes')}}</p>
                            <input type="checkbox" v-model="reasons.workTimes">
                        </div>

                        <div class="input-container">
                            <p class="label">{{$t('matches.detail.workProvider.modal.notUnlocked.reasons.hoursPerWeek')}}</p>
                            <input type="checkbox" v-model="reasons.hoursPerWeek">
                        </div>

                            <div class="input-container">
                            <p class="label">{{$t('matches.detail.workProvider.modal.notUnlocked.reasons.workLocation')}}</p>
                            <input type="checkbox" v-model="reasons.workLocation">
                        </div>

                        <div class="input-container">
                            <p class="label">{{$t('matches.detail.workProvider.modal.notUnlocked.reasons.hybrid')}}</p>
                            <input type="checkbox" v-model="reasons.hybrid">
                        </div>

                        <div class="input-container" v-if="type === 'worker'">
                            <p class="label">{{$t('matches.detail.workProvider.modal.notUnlocked.reasons.salary')}}</p>
                            <input type="checkbox" v-model="reasons.salary">
                        </div>

                        <div class="input-container other">
                            <p class="label">{{$t('matches.detail.workProvider.modal.notUnlocked.reasons.other')}}</p>
                            <textarea class="text-area" v-model="other" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="cta-container">
                <div class="cta cta-secondary cta-slim" :class="{disabled: !formValidated, 'loading dark': loading}" @click="imNotInterested()">{{$t('matches.detail.workProvider.modal.notUnlocked.submit')}}</div>
                <div class="cta cta-outline dark cta-slim" @click="close()">{{$t('language.cancel')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WorkProviderMatchDetailNotUnlockCandidate',

    props: {
        id: {
            type: String,
            default() {
                return ''
            }
        },

        show: {
            type: Boolean,
            default: false
        },

        type: {
            type: String,
            default() {
                return ''
            }
        },

        parentMatchId: {
            type: String,
            default() {
                return ''
            }
        },
        
        workProviderOfferId: {
            type: String,
            default() {
                return ''
            }
        }
    },

    watch: {
        show: function(val) {
            this.modal = val;
        },

        reasons: {
            deep: true,
            handler() {
                const self = this;
                let valid = false;

                Object.keys(self.reasons).map(key => {
                    if(self.reasons[key]) {
                        valid = true;
                    }
                })

                if(self.other) {
                    valid = true;
                }

                if(valid) {
                    self.formValidated = true;
                }else {
                    self.formValidated = false;
                }
            }
        },

        other: function() {
            if(this.other) {
                this.formValidated = true;
            }else {
                this.formValidated = false;
                Object.keys(this.reasons).map(key => {
                    if(this.reasons[key]) {
                        this.formValidated = true;
                    }
                })
            }
        },

        modal: function() {
            const self = this;
            if(self.modal) { self.$utils._open_modal(); }
            else { self.$utils._close_modal(); }
        }
    },

    data() {
        return {
            modal: false,
            formValidated: false,
            loading: false,

            reasons: {
                classification: false,
                workDays: false,
                workTimes: false,
                hoursPerWeek: false,
                workLocation: false,
                salary: false,
                hybrid: false,
            },

            other: ""
        }
    },

    methods: {
        imNotInterested: function() {
            const self = this; 

            if(self.formValidated && self.parentMatchId) {
                self.loading = true;

                const workProviderNotUnlockedReasons = [];
                Object.keys(self.reasons).map(key => {
                    if(self.reasons[key]) {
                        workProviderNotUnlockedReasons.push(key);
                    }
                })

                const data = {
                    stage: 'notUnlocked',
                    parentMatchId: self.parentMatchId,
                    workProviderNotUnlockedReasons: {
                        reasons: workProviderNotUnlockedReasons,
                        other: self.other
                    }
                }

                self.$axios
                    .post(`match/${self.type}/stage`, data)
                    .then(response => {
                        if(response.status == 200) {
                            self.$utils._navigate_to_name_with_params('work-provider-offer', { id: self.workProviderOfferId, state: 'consult' });
                        }
                    })
                    .catch(error => {
                        if(error && error.response) {
                            if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                        } else { console.log(error); }
                        
                        self.loading = false;
                    });
            }
        },

        close: function() {
            const self = this;
            
            self.modal = false;
            self.$emit('close');
        }
    }
}
</script>

