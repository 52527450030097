<template>
    <div class="row">
        <div class="fw">
            <div class="card card-header nomargin-top split">
                <div class="card-heading border-bottom">
                    <p class="title">{{$t('matches.detail.person.card.matchPercentage')}}</p>
                    <p class="percentage" v-html="$utils._computePercentageHTML(match.matchPercentage)"></p>
                </div>

                <div class="card-body">
                    <div class="row responsive desktop">
                        <div class="col6">
                            <div class="question-wrapper">
                                <div class="question-wrapper-heading">
                                    <p class="title" v-if="isPerson">{{$t('matches.detail.person.card.header.theirVolunteerCriteria')}}</p>
                                    <p class="title" v-else>{{$t(`matches.detail.workProvider.card.header.theirVolunteerCriteria.${type}`)}}</p>
                                </div>                                    
                            </div>
                        </div>

                        <span class="row-separator"></span>

                        <div class="col6">
                            <div class="question-wrapper">
                                <div class="question-wrapper-heading">
                                    <p class="title" v-if="isPerson">{{$t('matches.detail.person.card.header.yourVolunteerCriteria')}}</p>
                                    <p class="title" v-else>{{$t('matches.detail.workProvider.card.header.yourVolunteerCriteria')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-arrows responsive mobile">
                        <span :class="['cta cta-slim cta-outline dark arrow', { disabled: current_carousel_index === 0 }]" @click="carousel_index_previous()"><font-awesome-icon :icon="['fa', 'arrow-left']" /></span>
                        <p>{{ $t('matches.detail.person.card.header.carousel') }}</p>
                        <span :class="['cta cta-slim cta-outline dark arrow', { disabled:  current_carousel_index === max_carousel_index}]" @click="carousel_index_next()"><font-awesome-icon :icon="['fa', 'arrow-right']" /></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'MatchDetailCriteriaHeaderCard',

    props: {
        match: {
            type: Object,
            default() {
                return {}
            }
        },

        client: {
            type: String,
            default() {
                return ''
            }
        },

        type: {
            type: String,
            default() {
                return ''
            }
        },
    },

    computed: {
        ...mapGetters([
            'current_carousel_index',
            'max_carousel_index'
        ]),

        isWorkProvider: function() {
            return this.client === 'workProvider';
        },

        isPerson: function() {
            return this.client === 'person';
        }
    },

    methods: {
        ...mapActions([
            'carousel_index_next',
            'carousel_index_previous'
        ]),
    }
}
</script>

