<template>
    <div class="row">
        <div class="col12">
            <div class="card card-criteria-left card-contact nomargin-top green">
                <div class="card-heading border-bottom">
                    <p class="title" v-if="client === 'workProvider'">{{$t('matches.detail.workProvider.card.whats_next.title')}}</p>
                    <p class="title" v-if="client === 'person'">{{$t('matches.detail.person.card.whats_next.title')}}</p>
                </div>
                
                <div class="card-body">
                    <div class="column">
                        <p class="name" v-if="client === 'workProvider'">{{$t('matches.detail.workProvider.card.whats_next.desc')}}</p>
                        <p class="name" v-if="client === 'person'">{{$t('matches.detail.person.card.whats_next.desc')}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchDetailCriteriaContactCard',

    props: {
        contactMethod: {
            type: Object,
            default() {
                return {}
            }
        },

        client: {
            type: String,
            default() {
                return ''
            }
        }
    },

    computed: {
        format_contact_name: function() {
            const self = this;
            return `${self.contactMethod.person_unique_id} - ${self.contactMethod.name}`
        }
    }
}
</script>

