<template>
    <div class="row">
        <div class="fw">
            <div :class="['card card-summary split', { 'nomargin-top': globalStage === 'unlocked' }]">
                <div class="card-body">
                    <div class="question-wrapper">
                        <div class="row">
                            <div class="col6">
                                <div class="question name column">
                                    <div class="label-container">
                                        <p>{{ $t('matches.detail.person.card.summary.volunteerMatchNameLabel') }}</p>
                                    </div>

                                    <input class="input input-text read-only responsive desktop" :value="format_offer_name" type="text">
                                    <span class="input-mobile responsive mobile">{{ format_offer_name }}</span>
                                </div>

                                <OfferDescriptionComponent :description="workProviderOffer.description" :readonly="true" />
                            </div>

                            <span class="row-separator"></span>

                            <div class="col6">
                                <div v-if="globalStage === 'interested' || globalStage === 'unlocked'"
                                    class="question location column">
                                    <div class="label-container">
                                        <p v-if="matches.length === 1">
                                            {{ $t(`matches.detail.${client}.card.selectedLocation.${globalStage}`) }}</p>
                                        <p v-else>
                                            {{ $t(`matches.detail.${client}.card.selectedLocations.${globalStage}`) }}</p>
                                    </div>

                                    <div :class="['location-container read-only']">
                                        <div :class="['location', { active: match.stage === globalStage }]"
                                            v-for="match in orderedMatches" :key="match._id">
                                            <div class="text">
                                                <span>
                                                    <font-awesome-icon :class="{ active: match.stage === globalStage }" :icon="(match.stage === globalStage && globalStage !== 'notUnlocked') ? ['fa', 'circle-check'] : ['far', 'circle']"></font-awesome-icon>
                                                    <p class="name">{{ displayLocationForMatch(match) }}</p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-else class="question location column">
                                    <div class="label-container">
                                        <p v-if="matches.length === 1">{{ $t('matches.detail.person.card.location') }}</p>
                                        <p v-else>{{ $t('matches.detail.person.card.locations') }}</p>
                                    </div>

                                    <div class="single-location-container" v-if="matches.length === 1">
                                        <input class="input input-text read-only responsive desktop" :value="displayLocationForMatch(currentMatch)" type="text">
                                        <span class="input-mobile responsive mobile">{{ displayLocationForMatch(currentMatch) }}</span>
                                    </div>
                                    

                                    <multiselect    v-else 
                                                    v-model="currentMatch" 
                                                    :options="matches"
                                                    :close-on-select="true" 
                                                    :multiple="false" 
                                                    :allowEmpty="false"

                                                    :placeholder="$t('workProviderOffer.paid.card.interests.placeholder')"
                                                    :select-label="$t('language.multiselect.label.select')"
                                                    :selected-label="$t('language.multiselect.label.selected')"
                                                    :deselect-label="$t('language.multiselect.label.deselect')"
                                                    :custom-label="displayLocationForMatch"> 
                
                                                    <template slot="noResult">{{$t('language.multiselect.label.no_result')}}</template>
                                                    <template slot="noOptions">{{$t('language.multiselect.label.no_options')}}</template>
                                    </multiselect>
                                </div>

                                <div class="question stage column">
                                    <div class="label-container">
                                        <p>{{ $t('matches.detail.person.card.summary.volunteerMatchStage') }}</p>
                                    </div>

                                    <div v-if="deletion.deleted">
                                        <p class="stage">
                                            <span>{{ $t(`matches.stages.${deletion.deletedReason}`) }}</span>{{ by }}
                                            {{ deletion.since | moment("DD/MM/YYYY, H:mm") }}</p>
                                    </div>

                                    <div v-else>
                                        <div v-if="isWorkProvider">
                                            <p class="stage">
                                            <span>{{ $t(`matches.stages.long.${match.stage}.${client}.${type}`) }}</span>{{ by }}
                                            {{ match.stages[match.stage] | moment("DD/MM/YYYY, H:mm") }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="globalStage === 'notUnlocked' || globalStage === 'notInterested'"
                                    class="question reason column">
                                    <div class="label-container">
                                        <p>{{ $t(`matches.detail.shared.card.summary.reasons.title.${globalStage}.${client}`) }}</p>
                                    </div>

                                    <span
                                        v-for="(reason, index) in match[globalStage === 'notUnlocked' ? 'workProviderNotUnlockedReasons' : 'personNotInterestedReasons'].reasons"
                                        :key="reason">{{ `${index + 1}. ` +
                                                $t(`matches.detail.shared.card.summary.reasons.${client}.${globalStage}.${reason}`)
                                        }}</span>

                                    <div class="other" v-if="match[globalStage === 'notUnlocked' ? 'workProviderNotUnlockedReasons' : 'personNotInterestedReasons'].other">
                                        <span
                                            class="title">{{ $t('matches.detail.shared.card.summary.reasons.other') }}</span>
                                        <span class="text">{{ match[globalStage === 'notUnlocked' ? 'workProviderNotUnlockedReasons' : 'personNotInterestedReasons'].other }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OfferDescriptionComponent from '../../../Form/OfferDescriptionComponent.vue';

export default {
    name: 'MatchDetailSummaryCard',

    components: { OfferDescriptionComponent },

    props: {
        workProviderOffer: {
            type: Object,
            default() {
                return {}
            }
        },

        match: {
            type: Object,
            default() {
                return {}
            }
        },

        deletion: {
            type: Object,
            default() {
                return {}
            }
        },

        matches: {
            type: Array,
            default() {
                return {}
            }
        },

        workProviderOfferLocations: {
            type: Array,
            default() {
                return {}
            }
        },

        name: {
            type: String,
            default() {
                return ''
            }
        },

        globalStage: {
            type: String,
            default() {
                return ''
            }
        },

        client: {
            type: String,
            default() {
                return ''
            }
        },

        type: {
            type: String,
            default() {
                return ''
            }
        }
    },

    data() {
        return {
            currentMatch: {}
        }
    },

    watch: {
        currentMatch: function () {
            const self = this;

            self.$emit('updateMatch', self.currentMatch)
        }
    },

    computed: {
        isWorkProvider: function () {
            return this.client === 'workProvider';
        },

        isPerson: function () {
            return this.client === 'person';
        },

        by: function () {
            const self = this;

            if (self.match.stage === 'unlocked' || self.match.stage === 'notUnlocked' || self.match.stage === 'hired') {
                return self.isWorkProvider ? ` ${self.$t('matches.detail.person.card.summary.byYou')}` : ` ${self.$t('matches.detail.person.card.summary.byEmployer')}`;
            } else {
                return self.isWorkProvider ? ` ${self.$t('matches.detail.person.card.summary.byPerson')}` : ` ${self.$t('matches.detail.person.card.summary.byYou')}`;
            }
        },

        orderedMatches: function () {
            const self = this;

            if (self.globalStage === 'interested') {
                return self.matches.filter(match => match.deletedReason !== 'workProviderOfferLocationsChanged').sort(match => match.workLocation === 'regular').sort(match => match.stage === self.globalStage ? false : true);
            }

            return self.matches.filter(match => match.deletedReason !== 'workProviderOfferLocationsChanged' && match.stage === 'interested' || match.stage === 'unlocked').sort(match => match.workLocation === 'regular').sort(match => match.stage === self.globalStage ? false : true);
        },
        
        format_offer_name: function() {
            const self = this;
            return `${self.workProviderOffer._id.substring(self.workProviderOffer._id.length - 4, self.workProviderOffer._id.length).toUpperCase()} - ${self.workProviderOffer.name}`;
        }
    },

    methods: {
        displayLocationForMatch: function (match) {
            const self = this;

            if (match && match.workProviderOfferLocationId)
                return self.workProviderOfferLocations.find(loc => loc._id === match.workProviderOfferLocationId).formattedAddress
            else if (match && match.workLocation === 'remote')
                return self.$t('matches.workLocations.remote')

        }
    },

    mounted() {
        const self = this;

        self.currentMatch = self.match
    }
}
</script>

