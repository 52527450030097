<template>
    <div v-if="loaded" class="question work-times column">
        <div v-if="header" class="label-container">
            <p v-if="client === 'workProvider'">{{$t('workProviderOffer.shared.card.workTimes.title')}}</p>
            <p v-else>{{$t('workTimesLabel')}}</p>
            
            <p v-if="!readonly" class="subtitle">{{$t('workTimesSubtitle')}}</p>
        </div>

        <div v-if="data.workDays && data.workDays.length !== 0" class="accordion work-times">
            <div v-for="time in data.workTimes" :id="`accordion-item-${time.day}`" :key="time.day" class="accordion-item" :class="{active: time.active}">
                <div class="accordion-item-header">
                    <p>{{$t(time.day)}}</p>

                    <div v-if="!readonly" class="button-icon-container">
                        <font-awesome-icon @click="removeWorkDay(time.day)" :class="{ open: time.active }" :icon="['fa', 'circle-xmark']" />
                    </div>
                </div>
                <div v-if="time.active || force" class="accordion-item-body">
                    <div class="time-slot" v-for="(slot, index) in time.timeSlots" :key="index">
                        <div class="row">
                            <div class="input">
                                <p>{{$t('availableFrom')}}</p>
                                <div :id="`container-time-slot-${time.day}-${index}-start`">
                                    <vue-timepicker :id="`time-slot-${time.day}-${index}-start`" @close="handle_blur('workTimes', { day: time.day, slot: 'start' })" v-model="slot.start.value" :minute-interval="15" close-on-complete :class="[error('obj', time.day, 'start'), { disabled: readonly }]" placeholder="hh:mm"></vue-timepicker>
                                </div>
                                <p>{{$t('availableTo')}}</p>
                                <div :id="`container-time-slot-${time.day}-${index}-end`">
                                    <vue-timepicker :id="`time-slot-${time.day}-${index}-end`" @close="handle_blur('workTimes', { day: time.day, slot: 'end' })" v-model="slot.end.value" :minute-interval="15" close-on-complete :class="[error('obj', time.day, 'end'), { disabled: readonly }]" placeholder="hh:mm"></vue-timepicker>
                                </div>
                            </div>

                            <div class="error-copy-container" v-if="!readonly">
                                <div class="error-container desktop responsive" v-if="error('bool', time.day, 'start') || error('bool', time.day, 'end')">
                                    <p class="error" v-if="error('bool', time.day, 'start')"><font-awesome-icon :icon="['fa', 'circle-exclamation']" />{{$t(`errors.${validation.workTimes[time.day].start.key}`)}}</p>
                                    <p class="error" v-if="error('bool', time.day, 'end')"><font-awesome-icon :icon="['fa', 'circle-exclamation']" />{{$t(`errors.${validation.workTimes[time.day].end.key}`)}}</p>
                                </div>

                                <div :class="['copy']">
                                    <font-awesome-icon @click="copy_to_other_days(time.day)" :icon="['fa', 'copy']" />
                                </div>
                            </div>
                        </div>

                        <div class="error-container mobile responsive" v-if="error('bool', time.day, 'start') || error('bool', time.day, 'end')">
                            <p class="error" v-if="error('bool', time.day, 'start')"><font-awesome-icon :icon="['fa', 'circle-exclamation']" />{{$t(`errors.${validation.workTimes[time.day].start.key}`)}}</p>
                            <p class="error" v-if="error('bool', time.day, 'end')"><font-awesome-icon :icon="['fa', 'circle-exclamation']" />{{$t(`errors.${validation.workTimes[time.day].end.key}`)}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <span class="italic">{{ $t('work_times_empty') }}</span>
        </div>

        <div v-if="!in_match_detail && data.workDays && data.workDays.length !== 0" class="hours-per-week">
            <div v-if="client === 'worker' || (client === 'workProvider' && schedule_type === 'exact')" class="row">
                <div class="label-container">
                    <p v-if="client === 'worker'">{{$t('workProviderOffer.shared.card.hoursPerWeek.calculated_hours_per_week.worker')}}</p>
                    <p v-else>{{$t('workProviderOffer.shared.card.hoursPerWeek.calculated_hours_per_week.work_provider')}}</p>
                </div>

                <input id="calculated-hours-per-week" :class="['input input-text read-only']" type="text" v-model="data.calculated_hours_per_week" />
            </div>
            
            <div v-if="client === 'worker' || (client === 'workProvider' && schedule_type === 'flexible')" class="row">
                <div class="label-container">
                    <p v-if="client === 'worker'">{{$t('workerOffer.card.create.hoursPerWeek.title')}}</p>
                    <p v-else>{{$t('workProviderOffer.shared.card.hoursPerWeek.title')}}</p>
                </div>
                
                <div class="input-button-group">
                    <input id="entered-hours-per-week" v-on:blur="handle_blur('entered-hours-per-week')" :class="['input input-text', error('hoursPerWeek'), { 'readonly': readonly, 'read-only': data.hours_per_week_persons_choice }]" type="text" v-model="data.hoursPerWeek" @keypress="$utils.validate_hours_per_week($event, data.hoursPerWeek)" />
                    <button v-if="client === 'workProvider'" :class="['cta cta-option', { active: data.hours_per_week_persons_choice, 'readonly': readonly }]" @click="data.hours_per_week_persons_choice = !data.hours_per_week_persons_choice">{{$t('workProviderOffer.shared.card.hoursPerWeek.hours_per_week_persons_choice')}}</button>
                </div>
            </div>

            <FormError v-if="!readonly && client === 'worker' || (client === 'workProvider' && schedule_type === 'flexible')" :data="validation.hoursPerWeek" />
        </div>
    </div>
</template>

<script>
import FormError from '../../components/Utils/FormError.vue'

export default {
    name: 'WorkTimesComponent',

    components: { FormError },

    props: {
        workDays: {
            type: Array,
            default() {
                return []
            }
        },

        workTimes: {
            type: Array,
            default() {
                return []
            }
        },

        hoursPerWeek: {
            default: ''
        },

        schedule_type: {
            type: String,
            default: ''
        },

        hours_per_week_persons_choice: {
            type: Boolean,
            default: false
        },

        calculated_hours_per_week: {
            type: Number,
            default: 0
        },

        readonly: {
            type: Boolean,
            default: false
        },

        header: {
            type: Boolean,
            default: true
        },

        client: {
            type: String,
            default: 'worker'
        },

        validation: {
            type: Object,
            default() {
                return {}
            }
        },

        in_match_detail: {
            type: Boolean,
            default() {
                return false
            }
        },

        force: {
            type: Boolean,
            default() {
                return false
            }
        },
    },

    watch: {
        workTimes:  {
            deep: true,
            handler(val) {
                const self = this;

                if(self.loaded) {
                    this.data.calculated_hours_per_week = this.$utils._calculate_hours_per_week_from_work_times(val);
                    this.data.workTimes = val;
                }
            }
        },

        workDays: function(val) {
            this.data.workDays = val;
        },

        hoursPerWeek: function(val) {
            this.data.hoursPerWeek = val;
        },

        data: {
            deep: true,
            handler() {
                const self = this;

                if(self.loaded) {
                    self.$emit('update', self.data)
                }
            }
        },

        'data.hours_per_week_persons_choice': function() {
            const self = this;
            if(self.data.hours_per_week_persons_choice) { self.data.hoursPerWeek = '0'; }
        }
    },

    data() {
        return {
            data: {
                workDays: [],
                workTimes: [],
                hoursPerWeek: '',
                hours_per_week_persons_choice: false,
            },

            loaded: false
        }
    },

    methods: {
        removeWorkDay: function(day) {
            const self = this;

            self.data.workTimes.splice(self.data.workTimes.map(e => e.day).indexOf(day), 1);
            self.data.workDays.splice(self.data.workDays.indexOf(day), 1);
            
            self.$utils._re_order_work_days(self.data.workDays);

            self.$emit('update', self.data)
        },

        copy_to_other_days: function(day) {
            const self = this;
            const workTimes = self.data.workTimes[(self.data.workTimes.map(e => e.day).indexOf(day))];

            self.data.workTimes.forEach(time => {
                time.timeSlots[0].start.value = workTimes.timeSlots[0].start.value
                time.timeSlots[0].end.value = workTimes.timeSlots[0].end.value
            })

            self.$emit('update', self.data)
        },

        error: function(type, day, slot) {
            try {
                if(type === 'obj') {
                    if(!this.readonly) return { error: this.validation.workTimes[day][slot].error }
                }else if (type === 'bool') {
                    if(!this.readonly) return this.validation.workTimes[day][slot].error
                } else {
                    return this.validation.hoursPerWeek
                }
            } catch (e) {
                console.log(`${this.$options.name}: validation error`)
            }
        },

        handle_blur: function(key, additional_info = null) {
            const self = this;

            if(self.loaded) {
                if(key === 'entered-hours-per-week') {
                    if(self.data.hoursPerWeek !== 0 && self.data.hoursPerWeek !== '' && self.validation.hoursPerWeek.error === true) {
                        self.validation.hoursPerWeek.error = false;
                    } 
                } else if (key === 'workTimes') {
                    const work_times = self.data.workTimes[(self.data.workTimes.map(e => e.day).indexOf(additional_info.day))]

                    if(self.$utils._validate_time_slot(work_times.timeSlots[0][additional_info.slot].value) && self.validation.workTimes[additional_info.day][additional_info.slot].error === true) {
                        self.validation.workTimes[additional_info.day][additional_info.slot].error = false;
                    }
                }
            }
        }
    },

    mounted() {
        const self = this;

        if(self.workDays && self.workTimes) {
            self.data = {
                workDays: self.workDays,
                workTimes: self.workTimes,
                hoursPerWeek: self.hoursPerWeek,
                calculated_hours_per_week: self.calculated_hours_per_week,
                hours_per_week_persons_choice: self.hours_per_week_persons_choice,
            };

            self.$nextTick(() => {
                self.loaded = true;
                self.data.calculated_hours_per_week = self.$utils._calculate_hours_per_week_from_work_times(this.data.workTimes);
            })
        }
    }
}
</script>

