<template>
    <div v-if="client" :class="['row', { inverse: is_work_provider }]" v-match-heights="{el: [`${$screen.width > 768 ? '.card-start-date' : 'none'}`]}">
        <div :class="['card card-descriptor green']">
            <p class="descriptor">{{ $t('matches.detail.person.descriptors.startDate') }}</p> 
            <p class="percentage" v-html="$utils._computePercentageHTML(100)"></p>
        </div>
        
        <div class="col6">
            <div class="card card-criteria-left nomargin-top card-start-date">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.offer.startDate.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.offer.startDate`)}}</p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <span class="pill">{{ workProviderVolunteerOfferStartDate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col6">
            <div class="card card-criteria-right nomargin-top card-start-date">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.${type}.startDate.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.${type}.startDate`)}}</p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <span class="text" v-if="offer.availableNow">
                            {{$t(`matches.detail.${client}.card.${type}.availableNow`)}}
                        </span>

                        <span class="text" v-else>
                            {{$t(`matches.detail.${client}.card.${type}.nextTimeAvailable`, { date: $utils._formatDate(offer.nextTimeAvailable) })}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchDetailCriteriaStartDateCard',

    props: {
        client: { type: String, default: '' },
        type: { type: String, default: '' },

        match: {
            type: Object,
            default() {
                return {}
            }
        },

        workProviderOffer: {
            type: Object,
            default() {
                return {}
            }
        },

        offer: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    computed: {
        workProviderVolunteerOfferStartDate: function() {
            const self = this;
            
            if(new Date(self.workProviderOffer.startDate) > new Date()) {
                return this.$moment(self.workProviderOffer.startDate).format('YYYY-MM-DD');
            }else {
                return self.$t('language.words.asOfNow')
            }
        },

        is_person: function() {
            return this.client === 'person';
        },

        is_work_provider: function() {
            return this.client === 'workProvider';
        },

        device: function() {
            return this.$screen.width > 768 ? 'desktop' : 'mobile';
        }
    }
}
</script>

