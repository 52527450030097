<template>
    <div v-if="client" :class="['row', { inverse: is_work_provider }]" v-match-heights="{el: [`${$screen.width > 768 ? '.card-distance' : 'none'}`]}">
        <div :class="['card card-descriptor', { [`${generate_score_class}`]: true }]">
            <p class="descriptor">{{ $t('matches.detail.person.descriptors.distance') }}</p> 
            <p class="percentage" v-html="$utils._computePercentageHTML(match.calculationDetails['distance'])"></p>
        </div>
        
        <div class="col6">
            <div :class="{['card card-criteria-left nomargin-top card-distance']: true, [`${generate_score_class}`]: is_work_provider}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.offer.distance.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.offer.distance`)}}</p>
                    
                    <p class="percentage" v-if="is_work_provider" v-html="$utils._computePercentageHTML(match.calculationDetails['distance'])"></p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <span class="pill">{{ `${match.distance} km` }}</span>
                        <span v-if="is_person && device !== 'mobile'"  class="italic">{{$t('matches.detail.person.card.seeOnMap')}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="col6">
            <div :class="{['card card-criteria-right nomargin-top card-distance']: true, [`${generate_score_class}`]: is_person}">
                <div class="card-heading">
                    <p v-if="is_person" class="title">{{$t(`matches.detail.${client}.card.${type}.distance.${device}`)}}</p>
                    <p v-else class="title">{{$t(`matches.detail.${client}.card.${type}.distance`)}}</p>
                    
                    <p class="percentage" v-if="is_person" v-html="$utils._computePercentageHTML(match.calculationDetails['distance'])"></p>
                </div>

                <div class="card-body">
                    <div class="pill-container">
                        <span class="text" v-if="match.distance < offer.distanceRange">{{ $t(`matches.detail.${client}.card.${type}.distanceMatches`, {km: offer.distanceRange}) }}</span>
                        <span class="text" v-else>{{ $t(`matches.detail.${client}.card.${type}.distanceDoesNotMatch`, {km: offer.distanceRange}) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MatchDetailCriteriaDistanceCard',

    props: {
        client: { type: String, default: '' },
        type: { type: String, default: '' },

        match: {
            type: Object,
            default() {
                return {}
            }
        },

        workProviderOffer: {
            type: Object,
            default() {
                return {}
            }
        },

        offer: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    computed: {
        is_work_provider: function() {
            return this.client === 'workProvider';
        },

        is_person: function() {
            return this.client === 'person';
        },

        generate_score_class: function() {
            return this.$utils._generate_score_class(this.match, 'distance')
        },

        device: function() {
            return this.$screen.width > 768 ? 'desktop' : 'mobile';
        }
    }
}
</script>

